<template>
  <div class="flex flex1 height_100p template_center">
    <SidebarOne :lists="category_list" @click="handleCategory"></SidebarOne>
    <div class="course-container flex1 prel">
      <div class="list-toolbar flex flex_jcbetween flex_acenter">
        <div class="fontsize_14 color_26 list-toolbar-title">
          <span>选择以下课程模板快速创建您的课程或</span>
          <span class="create-span margin_left8" @click="createCourse">创建空白课程</span>
        </div>
        <el-input class="search-temp" placeholder="搜索模板" v-model="keyword" @change="changeSearch" @input="changeSearch">
          <i slot="prefix" class="el-input__icon el-icon-search color_8C margin_left2"></i>
        </el-input>
      </div>
      <div class="temp-navbar" >
        <ul class="temp-navbar-ul" ref="tempnavberRef" :class="{'flexwrap':showmore}">
          <li class="temp-navbar-li" :class="{ current: index == currentNav }"
            v-for="(item, index) in category_sub_list" :key="item.id" @click="tabNavItem(item, index)">
            {{ item.title }}
          </li>
        </ul>
        <span class="more" v-show="showmorebtn" @click="handleMore">{{showmore?'收起':'更多'}} <i class="fontsize_12" :class="{'icon-shanglajiantou':showmore,'icon-xialajiantou':!showmore}"></i></span>
      </div>
      <el-scrollbar
        ref="infiniteScroll"
        :wrapStyle="[{'overflow-x':'hidden'}]"
        class="list-container padding_top8"
        :class="{ 'list_nodata': total == 0, 'grid_type': courseListLen }"
        :style="{ '--right': '-38px' }" v-show="total > 0">
        <div class="coursebox-wrapper bgc_fff pointer" v-for="(item, ind) in courseList" :key="item.id + '_0'">
          <div class="coursebox-image prel">
            <div class="coursebox-image-box" 
              :style="{ 'background-image': `url(${item.cover})` }">
              <!-- <el-image :src="item.cover" class="coursebox-image-img" fit="cover">
              </el-image> -->
              <div class="coursebox-mark-corner" v-if="item.version">{{item.version}}</div>
              <img src="@/assets/theme_hg.png" class="coursebox-hg" v-if="item.is_vip==1">
            </div>
            <div class="coursebox-mask">
              <div class="coursebox-mask-tools pointer">
                <span class="fontsize_14 color_fff" @click="useCourseTemplate(item,ind)">使用</span>
              </div>
            </div>
          </div>
          <div class="coursebox-title fontsize_14 color_26 pover" v-html="item.title"></div>
          <div class="coursebox-tools fontsize_12">
            <div class="coursebox-tools-btn" @click.stop="previewTemp(item, ind)">预览</div>
          </div>
        </div>
      </el-scrollbar>
      <div class="nodata" v-if="total == 0">
        <img src="@/assets/nodata/temp_nodata.png" alt="" class="statu_img" />
        <span class="margin_top20 fontsize_12 color_8C">敬请期待…</span>
      </div>
    </div>
    <hint-dialog :visible.sync="tipVisible" :hint="hintObj" @click="toUpgrade" :ismore="true"></hint-dialog>
    <el-dialog title="" custom-class="preview-dialog" :visible.sync="previewVisible" width="1076px" top="15vh">
      <div>
        <el-image :src="previewSrc" class="" fit="cover" style="width:100%;"></el-image>
      </div>
    </el-dialog>
    <fd-upgrade-dialog :closeOnClickModal="false" :show.sync="upgradeDialogVisible"></fd-upgrade-dialog>
    <fd-loading :loading="fdloading"></fd-loading>
    <temp-dialog :visible.sync="tempdialog"></temp-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { getTempList, getTempCategory, useTemplate, previewTemplate } from "@/libs/api/template";
import { SidebarOne } from "@/renderer/components/Dashboard/components";
export default {
  components: {
    SidebarOne,
  },
  data() {
    return {
      courseList: [],
      fdloading: false,
      tempdialog: false,
      page: 1,
      total: 0,
      limit: 20,
      keyword: "",
      category_id: 0,
      category_list: [],
      category_sub_list: [],
      currentStyle: 0,
      dialogVisible: false,
      tipVisible: false,
      upgradeDialogVisible: false,
      previewVisible: false,
      currentNav: 0,
      currentTemp: -1,
      currentTheme: 0,
      currentTempNav: 0,
      previewSrc: '',
      hintObj:{},
      showmore:false,
      showmorebtn:false,
      infiniteScrollDistance: 100, //触底距离
    };
  },
  watch: {
    $route() {
      this.total = -1;
      this.keyword = "";
      this.category_list = [];
      this.getCategoryList();
    },
  },
  computed:{
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
    isShowMore(nVal){
      
    },
    courseListLen() {
      return this.courseList.length
    }

  },
  mounted() {
    // 获取课程列表
    this.getCategoryList();

    this.$refs.infiniteScroll.$refs.wrap.onscroll = this.onscroll;
  },
  methods: {
    ...mapActions(['CreateCourse']),
    onscroll() {
      let { offsetHeight, scrollTop, scrollHeight } = this.$refs.infiniteScroll.$refs.wrap;
      scrollHeight = scrollHeight - this.infiniteScrollDistance;
      if (offsetHeight + scrollTop >= scrollHeight) {
        // reach bottom
        this.loadData();
      }
    },
    loadData() {
      // 加载下一页
      if (this.page * this.limit >= this.total) return;
      this.page++;
      this.getList();
    },
    getCategoryList() {
      // 获取课程列表
      getTempCategory()
        .then((res) => {
          if (res.errorcode == 0) {
            this.category_list = res.data;
            if (res.data.length) {
              this.category_id = res.data[0].sub[0].id
              this.category_sub_list = res.data[0].sub
              this.getList();
            }
            this.showMoreBtn()
          }
        })
        .catch(() => {

        });
    },
    getList() {
      // 获取课程列表
      let params = {
        keyword: this.keyword,
        category_id: this.category_id,
        page: this.page,
        limit: this.limit,
      };
      getTempList(params)
        .then((res) => {
          if (res.errorcode == 0) {
            this.courseList =
              this.page > 1 ? this.courseList.concat(res.data) : res.data || [];
            this.total = res.total;
            
          } else {
            this.total = -1;
          }
        })
        .catch(() => {
          this.total = -1;
        });
    },
    showMoreBtn(){
      this.$nextTick(()=>{
        let childsWidth=0;
        let parentWidth = this.$refs.tempnavberRef.offsetWidth;
        let element =this.$refs.tempnavberRef.children;
        for (let i = 0; i < element.length; i++) {
          childsWidth+=element[i].offsetWidth;
        }
        this.showmorebtn=childsWidth > parentWidth?true:false
      })
    },
    changeSearch() {
      this.page = 1;
      this.getList()
    },
    handleCategory(item) {
      this.page = 1;
      this.showmore=false;
      this.currentNav = 0;
      this.category_id = item.sub[0].id;
      this.category_sub_list = item.sub;
      this.getList()
      this.showMoreBtn()
    },
    tabNavItem(item, index) {
      this.page = 1;
      this.currentNav = index;
      this.category_id = item.id;
      this.getList()
     
    },
    createCourse() {
      this.tempdialog = true;
    },
    // 使用模板
    useCourseTemplate(item) {
      this.fdloading = true;
      useTemplate({ id: item.id })
        .then((res) => {
          this.fdloading = false;
          if (res.errorcode == 10011 || res.errorcode == 10010) {
            this.$message.closeAll()
            this.hintObj = {
              content: res.msg,
              version: res.data.version
            }
            this.tipVisible = true;
          }
          if (res.errorcode == 0) {
            this.$message.success(res.msg)
            this.$router.push("/course/edit/" + res.data.course_id + "?pageType=index");
          }
        })
        .catch((err) => {
          this.fdloading = false;
        })
    },
    // 去升级
    toUpgrade() {
      this.tipVisible = false;
      this.upgradeDialogVisible = true;
    },
    // 预览主题
    previewTheme(item) {
      this.previewSrc = item.cover;
      this.previewVisible = true;
    },
    // 预览模板
    previewTemp(item) {
      this.fdloading = true;
      let pageFrame = null;
      if(this.is_web) {
        pageFrame = window.open("/#/preview/"+item.id,"preview");
      }
      
      previewTemplate({ id: item.id })
        .then((res) => {
          this.fdloading = false;
          if (res.errorcode == 0) {
            this.openNewWin( res, item.id, pageFrame );
          }
        })
        .catch((err) => {
          this.fdloading = false;
        })
    },
    openNewWin(res, id, pageFrame) {
      if(this.is_web) {
        pageFrame.location.replace(res.data.preview_url);
        return;
      }
      // electron端
      try {
        let { href } = this.$router.resolve({
          path: '/preview/'+id,
          query:{
            url: res.data.preview_url
          }
        });
        window.open(href, "_blank");
      } catch (error) {
        console.error( error )
      }
    },
    // 添加课程
    addCourse(obj) {
      // this.GetSchema();
      this.CreateCourse({ ...obj }).then(res => {
        this.$router.push('/course/edit/' + res.data.course_id + "?pageType=index");
        // this.$navBlank({
        //   path: "/course/edit/" + res.data.course_id,
        //   query: {
        //     pageType: "index"
        //   },
        //   pageId: res.data.course_id
        // });
      }).catch((err) => {
        console.log('创建课程失败')
      });
    },
    handleMore(){
      this.showmore=!this.showmore;

    }
  },
};
</script>

<style lang="less" scoped>
.template_center {
  background-color: #f5f5f5;
  padding: 24px 16px 22px;
  box-sizing: border-box;
}

.temp-navbar {
  position: relative;
  z-index: 1;
  margin-top: 16px;
  width: calc(100vw - 270px);
  display: flex;

  .more {
    text-align: center;
    height: 32px;
    line-height: 33px;
    flex-shrink: 0;
    font-size: 14px;
    color: #262626;
    transition: all 0.3s;
    margin-left: 24px;
    cursor: pointer;
    &:hover{
      color: #e74362;
      .icon-xialajiantou::before{
        color: #e74362;
      }
      .icon-shanglajiantou::before{
        color: #e74362;
      }
    }
  }

  &-ul {
    display: flex;
    width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &-li {
    flex-shrink: 0;
    font-size: 14px;
    color: #595959;
    line-height: 20px;
    padding: 6px 16px;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    transition: all 0.3s;
    margin-right: 16px;
    border: 1px solid #fff;
    cursor: pointer;
    margin-bottom: 12px;

    // &:first-child {
    //   margin-left: 0;
    // }

    &:hover {
      background: #f8eff1;
      border: 1px solid #f8eff1;
    }

    &.current {
      color: #fff;
      background: #e74362;
    }

    &.current2 {
      color: #e74362;
      border: 1px solid #e74362;
    }
  }
}

.nodata {
  position: relative;
  z-index: 0;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -130px;

  .statu_img {
    margin-top: 0;
    width: 100px;
  }
}



.course-container {
  box-sizing: border-box;
  padding: 5px 0 10px 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.list-toolbar {
  position: relative;
  z-index: 1;
  &-title {
    .create-span {
      color: #e74d6b;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #B5102F;
      }
    }
  }

  .search-temp {
    background: #ffffff;
    border-radius: 21px;
    width: 386px;
    height: 40px;
    line-height: 40px;

    /deep/.el-input__inner {
      border: none;
      border-radius: 21px;
      color: #8c8c8c;
      font-size: 12px;
      padding-left: 40px;

      &::placeholder {
        font-size: 12px;
      }
    }
  }
}

@liMarginRight: 24px;

.list-container {
  // margin-right: calc(var(--right)); //抵消li的margin-right
  // padding-right: 32px;
  height: 100%;
  overflow: hidden auto;

  // 滚动条占宽会导致页面对不齐
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  /deep/.el-scrollbar__wrap {
    width: 100%;
    margin-right: 0 !important;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &.list_nodata /deep/.el-scrollbar__view {
    height: 100%;
  }
  
  // 网格方式
  &.grid_type {
    /deep/.el-scrollbar__view {
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(224px,1fr));
      gap: 24px;
    }
    /deep/.el-scrollbar__bar.is-horizontal {
      display: none;
    }
    @media screen and (max-width: 1600px) {
      /deep/.el-scrollbar__view {
        grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
      }
    }
  }

}

.coursebox-wrapper {
  // overflow: hidden;
  // margin-bottom: 38px;
  // margin-right: @liMarginRight;
  box-sizing: border-box;
  border-radius: 4px;
  // width: 275px;
  // height: 254px;
  transition: all 0.5s ease;
  padding-bottom: 16px;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);

    .coursebox-mask {
      opacity: 1;
    }

    .coursebox-mask-tools {
      span {
        transform: translateY(0);
      }
    }

    .coursebox-mark-corner {
      transform: translateX(0);
    }
  }
}

.coursebox-image {
  border-radius: 4px 4px 0 0;
  // width: 275px;
  // height: 172px;
  width: 100%;
  padding-top: 65%;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;

  .coursebox-image-box {
    overflow: hidden;
    position: relative;
    display: block;
    border-radius: 4px 4px 0 0;
    width: 100%;
    padding-top: 65%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

  }

  .coursebox-image-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.coursebox-tools {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
  padding: 0 8px;

  &-btn {
    color: #595959;
    height: 30px;
    min-width: 100px;
    width: 67%;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    transition: all 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.coursebox-mask {
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);

  &.show {
    opacity: 1;
  }
}

.coursebox-mask-tools {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px 4px 0px 0px;

  span {
    width: 118px;
    height: 32px;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    // transform: translateY(60px);
    transition: all 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.coursebox-mark-corner {
  height: 21px;
  line-height: 21px;
  font-size: 12px;
  color: #fff;
  padding: 0 6px;
  background: #fe840f;
  border-radius: 4px 0 4px 0;
  position: absolute;
  left: 0;
  top: 0;
  // z-index: 99;
  // transform: translateX(-64%);
  transition: all 0.3s;

  &:hover {
    transform: translateX(0);
  }
}

.coursebox-hg {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 48px;
}

.coursebox-title {
  width: 224px;
  height: 20px;
  line-height: 20px;
  padding: 6px 8px 10px;
}

.coursebox-title /deep/p {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/.preview-dialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    right: 16px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #8C8C8C;
    font-size: 20px;
    font-weight: 700;
  }

  .el-dialog__body {
    padding: 52px 16px 16px;
  }
}

</style>
